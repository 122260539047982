<template>
	<b-modal
		centered
		v-model="open"
		:title="update ? 'Actualizar contacto' : 'Guardar contacto'"
		@ok.prevent="saveOrganizer"
		@hidden="resetValues"
	>
		<validation-observer tag="b-row" ref="form">
			<b-col cols="12">
				<validation-provider rules="required" v-slot="{ errors, valid }">
					<b-form-group label="Nombre" label-for="name">
						<b-form-input
							placeholder="Nombre del contacto"
							v-model="organizerName"
							:state="errors[0] ? false : valid ? true : null"
							@input="(val) => (organizerName = organizerName.toUpperCase())"
						/>
						<span style="color: red">{{ errors[0] }}</span>
					</b-form-group>
				</validation-provider>
			</b-col>

			<b-col cols="12">
				<validation-provider rules="required" v-slot="{ errors, valid }">
					<b-form-group label="Telefono" label-for="phone">
						<b-form-input
							v-model="phone"
							:state="errors[0] ? false : valid ? true : null"
							v-mask="'###-###-###'"
							placeholder="Ingrese numero de telefono"
						/>
						<span style="color: red">{{ errors[0] }}</span>
					</b-form-group>
				</validation-provider>
			</b-col>

			<b-col cols="12" class="d-flex align-items-center justify-content-end">
				<div
					@click="
						addPhone1 = !addPhone1
						phone_aux1 = null
						phone_aux2 = null
					"
					class="d-flex align-items-center cursor-pointer"
				>
					<p class="mb-0 add-phone font-small-2">Agregar telefono alternativo</p>
					<feather-icon icon="PlusIcon" size="20" />
				</div>
			</b-col>

			<template v-if="addPhone1">
				<b-col cols="12">
					<validation-provider rules="required" v-slot="{ errors, valid }">
						<b-form-group label="Telefono Alternativo" label-for="phone_aux1">
							<b-form-input
								v-model="phone_aux1"
								:state="errors[0] ? false : valid ? true : null"
								v-mask="'###-###-###'"
								placeholder="Ingrese numero de telefono alternativo"
							/>
							<span style="color: red">{{ errors[0] }}</span>
						</b-form-group>
					</validation-provider>
				</b-col>
				<b-col cols="12" class="d-flex align-items-center justify-content-end">
					<div
						@click="
							addPhone2 = !addPhone2
							phone_aux2 = null
						"
						class="d-flex align-items-center cursor-pointer"
					>
						<p class="mb-0 font-small-2">Agregar otro telefono alternativo</p>
						<feather-icon icon="PlusIcon" size="20" />
					</div>
				</b-col>
				<b-col cols="12" v-if="addPhone2">
					<validation-provider rules="required" v-slot="{ errors, valid }">
						<b-form-group label="Otro Telefono Alternativo" label-for="phone_aux1">
							<b-form-input
								v-model="phone_aux2"
								:state="errors[0] ? false : valid ? true : null"
								v-mask="'###-###-###'"
								placeholder="Ingrese otro numero de telefono"
							/>
							<span style="color: red">{{ errors[0] }}</span>
						</b-form-group>
					</validation-provider>
				</b-col>
			</template>

			<!-- <b-col cols="12">
				<b-form-group label="Torneos" label-for="tournament">
					<v-select
						multiple
						v-model="selectTournaments"
						:options="tournaments"
						:clearable="false"
						label="label"
						:reduce="(option) => option.value"
						transition=""
						placeholder="ELIJA EL TORNEO"
			/>
				</b-form-group>
			</b-col> -->
		</validation-observer>
	</b-modal>
</template>

<script>
import contactService from "../contacts.service"
// import organizerService from "../../organizers/organizer.service";
import organizerService from "../../../../organizers/organizer.service"
import vSelect from "vue-select"
import { mapState } from "vuex"

export default {
	name: "FormContactForm",
	components: { vSelect },
	props: {
		data: {
			type: Object,
		},
		openModal: {
			type: Boolean,
		},
		update: {
			type: Boolean,
			default: false,
		},
		id: {
			type: Number,
			default: null,
		},
	},
	computed: {
		...mapState("auth", ["currentUser"]),
	},
	data() {
		return {
			open: false,
			tournaments: [],
			organizerName: null,
			selectTournaments: [],
			phone: null,
			phone_aux1: null,
			phone_aux2: null,
			addPhone1: false,
			addPhone2: false,
		}
	},
	async mounted() {
		this.open = this.openModal
		this.organizerName = this.data.organizerName
		this.selectTournaments = this.data.selectTournaments
		this.phone = this.data.phone
		this.phone_aux1 = this.data.phone_aux1
		this.phone_aux2 = this.data.phone_aux2
		this.addPhone1 = this.data.addPhone1
		this.addPhone2 = this.data.addPhone2
	},
	methods: {
		async saveOrganizer() {
			const data = {
				name: this.organizerName,
				user_id: this.currentUser.user_id,
				tournaments: this.selectTournaments,
				phone: this.phone,
				phone_aux1: this.phone_aux1,
				phone_aux2: this.phone_aux2,
			}
			const success = await this.$refs.form.validate()
			if (success) {
				if (!this.update) {
					try {
						const {
							data: { ok, msg, contact_id },
						} = await contactService.saveContactByTournaments(data)
						if (ok) {
							this.showSuccessToast(msg, "success", "top-left", "Cliente actualizado", "CheckIcon")
							this.$emit("new-record", contact_id)
							this.resetValues()
						} else {
							this.showSuccessToast(msg, "warning", "top-left", "Cliente actualizado", "InfoIcon")
						}
					} catch (e) {
						const {
							data: { errors, message },
						} = e.response
						// console.log('Error: ', errors.name)
						this.showSuccessToast(errors.name[0], "warning", "top-left", "Cliente actualizado", "InfoIcon")
					}
				} else {
					try {
						const {
							data: { ok, msg },
						} = await contactService.updateContactById({ ...data, contact_id: this.id })
						if (ok) {
							this.showSuccessToast(msg, "success", "top-left", "Cliente actualizado", "CheckIcon")
							this.resetValues()
						} else {
							this.showSuccessToast(msg, "warning", "top-left", "Cliente actualizado", "InfoIcon")
						}
					} catch (e) {
						const {
							data: { errors, message },
						} = e.response
						// console.log('Error: ', errors.name)
						this.showSuccessToast(errors.name[0], "warning", "top-left", "Cliente actualizado", "InfoIcon")
					}
				}
				//organizerService
			}
		},

		resetValues() {
			this.organizerName = null
			this.selectTournaments = []
			this.phone = null
			this.phone_aux1 = null
			this.phone_aux2 = null
			this.addPhone1 = null
			this.addPhone2 = null

			this.open = false
			this.$emit("resetModal")
		},
	},
	watch: {
		openModal() {
			this.open = this.openModal
		},
	},
}
</script>

<style scoped>
.add-phone {
	margin-right: 5px;
}
</style>
