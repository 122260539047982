var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"centered":"","title":_vm.update ? 'Actualizar contacto' : 'Guardar contacto'},on:{"ok":function($event){$event.preventDefault();return _vm.saveOrganizer($event)},"hidden":_vm.resetValues},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('validation-observer',{ref:"form",attrs:{"tag":"b-row"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-group',{attrs:{"label":"Nombre","label-for":"name"}},[_c('b-form-input',{attrs:{"placeholder":"Nombre del contacto","state":errors[0] ? false : valid ? true : null},on:{"input":function (val) { return (_vm.organizerName = _vm.organizerName.toUpperCase()); }},model:{value:(_vm.organizerName),callback:function ($$v) {_vm.organizerName=$$v},expression:"organizerName"}}),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-group',{attrs:{"label":"Telefono","label-for":"phone"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###-###-###'),expression:"'###-###-###'"}],attrs:{"state":errors[0] ? false : valid ? true : null,"placeholder":"Ingrese numero de telefono"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-center cursor-pointer",on:{"click":function($event){_vm.addPhone1 = !_vm.addPhone1
					_vm.phone_aux1 = null
					_vm.phone_aux2 = null}}},[_c('p',{staticClass:"mb-0 add-phone font-small-2"},[_vm._v("Agregar telefono alternativo")]),_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"20"}})],1)]),(_vm.addPhone1)?[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
					var errors = ref.errors;
					var valid = ref.valid;
return [_c('b-form-group',{attrs:{"label":"Telefono Alternativo","label-for":"phone_aux1"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###-###-###'),expression:"'###-###-###'"}],attrs:{"state":errors[0] ? false : valid ? true : null,"placeholder":"Ingrese numero de telefono alternativo"},model:{value:(_vm.phone_aux1),callback:function ($$v) {_vm.phone_aux1=$$v},expression:"phone_aux1"}}),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2785537083)})],1),_c('b-col',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-center cursor-pointer",on:{"click":function($event){_vm.addPhone2 = !_vm.addPhone2
						_vm.phone_aux2 = null}}},[_c('p',{staticClass:"mb-0 font-small-2"},[_vm._v("Agregar otro telefono alternativo")]),_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"20"}})],1)]),(_vm.addPhone2)?_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
						var errors = ref.errors;
						var valid = ref.valid;
return [_c('b-form-group',{attrs:{"label":"Otro Telefono Alternativo","label-for":"phone_aux1"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###-###-###'),expression:"'###-###-###'"}],attrs:{"state":errors[0] ? false : valid ? true : null,"placeholder":"Ingrese otro numero de telefono"},model:{value:(_vm.phone_aux2),callback:function ($$v) {_vm.phone_aux2=$$v},expression:"phone_aux2"}}),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,978176381)})],1):_vm._e()]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }