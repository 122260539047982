<template>
	<div>
		<b-button class="btn-icon" variant="outline-success" size="sm" @click="openModal = true" v-if="simple">
			<feather-icon icon="PlusIcon" />
		</b-button>
		<button @click="openModal = true" class="btn btn-primary ml-2 btn-agregar-organizer" v-else>
			Agregar contacto
		</button>

		<form-contact-brain
			:data="data"
			:openModal="openModal"
			@resetModal="resetModal"
			@new-record="($event) => $emit('new-record', $event)"
		/>
	</div>
</template>

<script>
import FormContactBrain from "./FormContactBrain"
//organizerService

export default {
	name: "CreateContactBrain",
	components: { FormContactBrain },
	props: {
		simple: Boolean,
	},
	data() {
		return {
			openModal: false,
			tournaments: [],
			organizerName: null,
			selectTournaments: [],
			data: {
				organizerName: null,
				selectTournaments: [],
				phone: null,
				phone_aux1: null,
				phone_aux2: null,
			},
		}
	},

	methods: {
		resetModal() {
			this.openModal = false
			this.$emit("refreshModal")
		},
	},
}
</script>

<style lang="scss" scoped>
.btn-agregar-organizer {
	width: 140px !important;
	font-size: 10px;
	@media screen and (min-width: 600px) {
		width: 180px !important;
		font-size: 14px !important;
	}
}
</style>
