import axios from "@/axios";

class OrganizerServiceBrain {
    async saveOrganizer(body) {
        const data = await axios.post(`/api/organizer/save`, body)
        return data;
    }
    async getOrganizersToStatus(body) {
        const data = await axios.post(`/api/organizer/get-to-status`, body)
        return data;
    }
    async updateStatus(body) {
        const data = await axios.put(`/api/organizer/update-status`, body)
        return data;
    }

    async getLotesByNotNull(){
        const data = await axios.get(`/api/lote/organizer/get-lotes-by-organizer`)
        return data;
    }
    //organizer/save-organizer-by-tournaments
    async saveOrganizerByTournaments(body) {
        const data = await axios.post(`/api/organizer/save-organizer-by-tournaments`, body)
        return data;
    }
    async deleteOrganizerById({ id }) {
        const data = await axios.delete(`/api/organizer/delete-by-id/${id}`)
        return data;
    }
    async updateOrganizerById(body) {
        const data = await axios.put(`/api/organizer/update-by-id`, body)
        return data;
    }
}
export default new OrganizerServiceBrain()